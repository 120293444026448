<template>
  <div>
    <el-form label-position="left" label-width="auto">
      <el-form-item :label="$t('title.ordernumber')">
        {{ form.orderCode }}
      </el-form-item>
      <el-form-item :label="$t('page.createTime')">
        {{ form.returnCreateTime }}
      </el-form-item>
      <el-form-item :label="$t('topic.BuyerID')">
        {{ form.buyerUsername }}
      </el-form-item>
      <el-form-item :label="$t('topic.Typerefund')">
        {{ form.detailList[0].refundType === 1 ? $t('topic.Fullrefund') : $t('topic.Partialrefund') }}
      </el-form-item>
      <el-form-item :label="$t('title.Freight')">
        <el-input
          v-if="form.detailList && form.detailList.length && form.detailList[0].shippingPrice"
          v-model="form.detailList[0].shippingPrice"
        >
          <template #prepend>$</template>
        </el-input>
        <span v-else>{{ $t('title.None') }}</span>
      </el-form-item>
      <el-form-item label="退还运费">
        <el-checkbox
          v-model="form.returnShipment"
          :false-label="0"
          :true-label="1"
        >
          {{ $t('topic.Ticktoshipping') }}
        </el-checkbox>
      </el-form-item>
      <el-form-item :label="$t('title.Totalrefund')">
        {{ totalRefundAmount }}
      </el-form-item>
      <el-table
        :data="form.detailList"
        :header-cell-style="{background: '#fafafa'}"
        default-expand-all
        fit
      >
        <el-table-column align="center" type="expand">
          <template #default="{row, $index}">
            <el-descriptions
              :column="4"
              :content-style="{'text-align': 'center'}"
              :label-style="{'text-align': 'center', color: '#909399', 'font-weight': 700}"
              border
              direction="vertical"
            >
              <el-descriptions-item>
                {{ $t('topic.Productfee') }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('title.orderamount')">
                {{ row.itemPrice }}
              </el-descriptions-item>
              <el-descriptions-item :label="$t('topic.Orderrefund')">
                <el-input
                  :disabled="refundType !== 2"
                  :value="row.myItemPrice"
                  @change="(val) => handlePriceChange($index, row, val)"
                  @input="(val) => $set(row, 'myItemPrice', val)"
                >
                  <template #prepend>
                    <span>$</span>
                  </template>
                  <template #append>
                    <el-button @click="handleRecover($index, row)">还原</el-button>
                  </template>
                </el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <el-input
                  v-model="row.mypromotion"
                  :disabled="refundType !== 2"
                  @input="handlePromotionChange($index)"
                >
                  <template #append>
                    <span>%</span>
                  </template>
                </el-input>
              </el-descriptions-item>
              <el-descriptions-item :label-style="{display:'none'}">
                {{ $t('title.commodityTax') }}
              </el-descriptions-item>
              <el-descriptions-item :label-style="{display:'none'}">
                {{ row.itemTax }}
              </el-descriptions-item>
              <el-descriptions-item :label-style="{display:'none'}" :span="2">
                <el-input
                  v-model="row.myItemTax"
                  disabled
                >
                  <template #prepend>
                    <span>$</span>
                  </template>
                </el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                {{ $t('title.Totalrefund') }}
              </el-descriptions-item>
              <el-descriptions-item>
                {{ row.myrefundAmount }}
              </el-descriptions-item>
              <el-descriptions-item>
                <el-input
                  v-model="row.refundAmount"
                  :disabled="refundType !== 2"
                  handle-total-change
                  @change="(val) => handleTotalChange(val, $index, row)"
                >
                  <template #prepend>
                    <span>$</span>
                  </template>
                </el-input>
              </el-descriptions-item>
            </el-descriptions>
            <el-col :span="20">
              <el-form-item :label="$t('title.Buyerpessage')">
                <el-input
                  v-model="row.sellComments"
                  :placeholder="$t('page.inputPlaceholder')"
                  :rows="2"
                  style="width: 500px;"
                  type="textarea"
                />
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item :label="$t('title.stytemNote') ">
                <el-input
                  v-model="row.remark"
                  :placeholder="$t('page.inputPlaceholder')"
                  :rows="2"
                  style="width: 500px;"
                  type="textarea"
                />
              </el-form-item>
            </el-col>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('title.Refundinformation')"
          align="center"
        >
          <template #default="{row}">
            <div class="refund-info">
              <el-image
                :src="row.mainImage"
                fit="contain"
                style="width: 50px; height: 50px;min-width: 50px;min-height: 50px"
              />
              <span>{{ row.sku }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('topic.Numberofrefunds')"
          align="center"
          prop="returnNumber"
          width="80"
        />
        <el-table-column
          :label="$t('topic.reasonforreturn')"
          align="center"
        >
          <template #default="{row}">
            <el-select
              v-model="row.returnReasonCode"
              :placeholder="$t('page.selectPlaceholder')"
              disabled
            >
              <el-option
                v-for="(item,index) in returnReasonOptions"
                :key="item.returnReasonCodeEN + index"
                :label="language === 'en-US' ? item.returnReasonCodeEN : item.returnReasonCodeCN"
                :value="item.returnReasonCodeEN"
              />
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div style="text-align: center;margin-top: 20px">
      <el-button @click="goBack">{{ $t('title.back') }}</el-button>
      <el-button type="primary" @click="saveRefund">{{ $t('page.sure') }}</el-button>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { getAllReasonCode, getDetailReturnsInfoById, saveReturnInfos, updateStatus } from '@/api/omsorder'
import mixin from '@/mixin/oms-mixin'

export default {
  name: 'StandWebsiteRefundsRefactor',
  mixins: [mixin],
  data() {
    return {
      language: '',
      form: {},
      returnReasonOptions: [],
      totalRefundAmount: 0
    }
  },
  computed: {
    refundType() {
      return +this.$route.query.refundType
    }
  },
  watch: {
    'form.returnShipment'(val) {
      this.calculateTotalRefundAmount(val)
    }
  },
  created() {
    const lang = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    const erplang = this.$store.getters.language && this.$store.getters.language === 'en' ? 'en-US' : 'zh-CN'
    this.language = erplang || lang
    this.getDetailReturnsInfoById()
    this.initData()
  },
  methods: {
    calculateTotalRefundAmount(isReturnShipment) {
      const totalRefundAmount = this.form.detailList.reduce((total, item) => {
        return total + (+item.refundAmount || 0)
      }, 0).toFixed(2)
      if (isReturnShipment) {
        this.totalRefundAmount = +totalRefundAmount + (this.form.detailList?.[0]?.shippingPrice || 0)
      } else {
        this.totalRefundAmount = +totalRefundAmount
      }
    },
    initData() {
      const storeData = sessionStorage.getItem('indatas')
      this.form = storeData ? JSON.parse(storeData) : {}
      this.form.detailList = this.form.detailList?.map(e => {
        // 保存源字段，相当于添加了新属性，因此没有响应式
        e.originalMyItemPrice = e.myItemPrice
        e.originalMyItemTax = e.myItemTax
        e.orderCode = e.orderNumber ? e.orderNumber : e.orderCode
        e.myShippingPrice = e.shippingPrice ? e.shippingPrice : 0
        e.myShippingTax = e.shippingTax ? e.shippingTax : 0 // 判断后端的shippingTax字段有没有  没有字段前端写死且值默认为0
        e.shippingTax = e.shippingTax ? e.shippingTax : 0 // 判断后端的shippingTax字段有没有  没有字段前端写死且值默认为0
        e.myrefundAmount = (parseFloat(e.myItemPrice) + parseFloat(e.myItemTax ? e.myItemTax : 0)).toFixed(2)
        e.myotherRefund = e.otherRefund
        e.mypromotion = 100
        e.myItemTaxPromotion = 100
        e.shippingPriceCheckbox = false
        e.shippingCheckbox = true
        e.refundAmount = (parseFloat(e.myItemPrice) + parseFloat(e.myItemTax) + parseFloat(e.myShippingPrice) + parseFloat(e.myShippingTax) + parseFloat(e.otherRefund)).toFixed(2)
        return e
      }) || []
      this.form.orderCode = this.form.returnsInfo.orderCode
      this.form.buyerUsername = this.form.returnsInfo.buyerUsername
      this.getAllReasonCode(this.form.returnsInfo.platFormName)
    },
    async getDetailReturnsInfoById() {
      const { id } = this.$route.query
      if (!id) return
      let { datas } = await getDetailReturnsInfoById(id)
      if (!datas) {
        datas = {}
      }
      datas.detailList = datas.detailList?.map(e => {
        e.originalMyItemPrice = e.myItemPrice
        e.originalMyItemTax = e.myItemTax
        e.myShippingPrice = e.shippingPrice ? e.shippingPrice : 0
        e.myShippingTax = e.shippingTax ? e.shippingTax : 0
        e.myrefundAmount = (parseFloat(e.myItemPrice) + parseFloat(e.myItemTax)).toFixed(2)
        e.myOtherRefund = e.otherRefund
        e.mypromotion = 100
        e.shippingPriceCheckbox = false
        e.shippingCheckbox = true
        e.refundAmount = (parseFloat(e.myItemPrice) + parseFloat(e.myItemTax)).toFixed(2)
        return e
      }) || []
      this.form = datas
      await this.getAllReasonCode(this.form.platFormName)
    },
    async getAllReasonCode(platform) {
      const { datas } = await getAllReasonCode(platform)
      this.returnReasonOptions = datas || []
    },
    saveRefund() {
      if (this.form.detailList.some(row => !row.refundAmount)) {
        this.$message.error(this.$t('tips.ThanOriginal'))
        return
      }
      this.$confirm(this.$t('tips.refundAmountTips'), this.$t('page.Prompt'), {
        confirmButtonText: this.$t('title.confirm'),
        cancelButtonText: this.$t('title.cancel'),
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            this.form.detailList.forEach(e => {
              e.orderCode = e.orderNumber ? e.orderNumber : e.orderCode
              e.shippingTax = e.myShippingTax
            })
            instance.confirmButtonLoading = true
            instance.cancelButtonLoading = true
            if (+this.$route.query.repostType === 1) {
              // 重推
              await this.handleRetweet(instance)
              done()
              return
            }
            if (+this.$route.query.repostType === 2) {
              // 手工创建保存后 点发布按钮校验 如果选中是仅退款(全额 或 部分退款)
              const data = {}
              const {
                returnShipment,
                rmaId,
                id,
                remark,
                returnDetailType,
                sellComments,
                orderCode,
                returnType,
                siteCode,
                shopCode,
                platFormName,
                buyerUsername,
                shipperAddress
              } = this.form
              data.detailList = this.form.detailList
              data.imageList = this.form.imageList
              data.returnsInfo = {
                returnShipment,
                rmaId,
                id,
                remark,
                returnDetailType,
                orderCode,
                siteCode,
                shopCode,
                returnType,
                sellComments,
                platFormName,
                buyerUsername
              }
              data.returnsTrack = { shipperAddress, orderCode }
              data.operating = 1
              await this.handleSaveReturnInfo(data, instance)
              done()
              return
            }
            if (+this.$route.query.repostType === 3) {
              // 手工创建直接发布  如果选中是仅退款(全额 或 部分退款)
              await this.handleSaveReturnInfo(this.form, instance)
              done()
              return
            }
            // 系统抓取的退款
            this.form.operatorId = 3
            await this.handleRetweet(instance)
            done()
          } else {
            done()
          }
        }
      })
    },
    updatePriceTax(data) {
      data.myItemPrice = parseFloat(data.originalMyItemPrice) * (parseFloat(data.mypromotion) / 100)
      data.myItemPrice = Number(data.myItemPrice.toString().match(/^\d+(?:\.\d{0,2})?/))
      data.myItemTax = parseFloat(data.originalMyItemTax) * (parseFloat(data.mypromotion) / 100)
      data.myItemTax = Number(data.myItemTax.toString().match(/^\d+(?:\.\d{0,2})?/))
    },
    updateRefundAmount(data, index) {
      data.refundAmount = (parseFloat(data.myItemPrice) + parseFloat(data.myItemTax)).toFixed(2)
      this.$set(this.form.detailList, index, data)
      this.calculateTotalRefundAmount(this.form.returnShipment)
    },
    handlePriceChange(index, row, val) {
      const orgPrice = row.originalMyItemPrice
      val = (Math.round(val * 100) / 100).toFixed(2)
      if (!val || val < 0) {
        this.$message.warning(this.$t('tips.GreaterThan0'))
        return
      }
      if (val > orgPrice) {
        this.$message.warning(this.$t('tips.EnterOriginal'))
        return
      }
      const reduce = (parseFloat(val) - parseFloat(orgPrice)) / parseFloat(orgPrice)
      row.myItemPrice = val
      row.myItemTax = (parseFloat(row.itemTax) * (1 + reduce)).toFixed(2)
      row.mypromotion = ((1 + reduce) * 100).toFixed(2)
      this.$set(this.form.detailList, index, row)
      this.handlePromotionChange(index)
    },
    handleRecover(index, row) {
      row.myItemPrice = row.originalMyItemPrice
      row.mypromotion = 100
      row.myItemTax = row.originalMyItemTax
      this.$set(this.form.detailList, index, row)
    },
    handlePromotionChange(index, e) {
      const data = this.form.detailList[index]
      if (e !== undefined) {
        data.shippingPriceCheckbox = e
      }
      if (parseFloat(data.myItemTax) > parseFloat(data.originalMyItemTax)) {
        this.$alert(this.$t('tips.EnterOriginal'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure')
        }).then(() => {
          data.myItemTax = data.itemTax
          this.updateRefundAmount(data, index)
        })
      }
      if (parseFloat(data.myItemPrice) > parseFloat(data.originalMyItemPrice)) {
        this.$alert(this.$t('tips.EnterOriginal'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure')
        }).then(() => {
          data.myItemPrice = data.originalMyItemPrice
          this.updateRefundAmount(data, index)
        })
      }
      if (data.mypromotion <= 0) {
        this.$alert(this.$t('tips.GreaterThan'), this.$t('page.Prompt'), {
          confirmButtonText: this.$t('page.sure')
        }).then(() => {
          data.mypromotion = 100
          this.updatePriceTax(data, index)
          this.updateRefundAmount(data, index)
        })
      }
      if (data.mypromotion >= 100) {
        data.mypromotion = 100
      }
      if (!this.showRow) {
        this.updatePriceTax(data, index)
      }

      data.otherRefund = Number(data.otherRefund.toString().match(/^\d+(?:\.\d{0,2})?/))
      this.updateRefundAmount(data, index)
    },
    handleTotalChange(val, index, row) {
      // 判断是否是数字
      if (!/^\d+(\.\d+)?$/.test(val)) {
        row.refundAmount = row.myrefundAmount
        this.$message.warning(this.$t('tips.InputNumber'))
        return
      }
      val = parseFloat(val)
      if (val === '') {
        row.refundAmount = row.myrefundAmount
        this.$message.warning(this.$t('tips.GreaterThan0'))
        return
      }
      if (val < 0) {
        row.refundAmount = row.myrefundAmount
        this.$message.warning(this.$t('tips.GreaterThan0'))
        return
      }
      if (val > row.myrefundAmount) {
        row.refundAmount = row.myrefundAmount
        this.$message.warning(this.$t('tips.EnterOriginal'))
        return
      }
      const promotion = (parseFloat(val) / parseFloat(row.myrefundAmount)).toFixed(4)
      row.mypromotion = promotion * 100
      row.myItemPrice = +(parseFloat(row.originalMyItemPrice) * (parseFloat(promotion))).toFixed(2)
      row.myItemTax = (parseFloat(val) - parseFloat(row.myItemPrice)).toFixed(2)
      this.$set(this.form.detailList, index, row)
      this.calculateTotalRefundAmount(this.form.returnShipment)
    },
    async handleRetweet(instance) {
      this.form.operatorId = 5
      const { msg } = await updateStatus([this.form]).finally(() => {
        instance.confirmButtonLoading = false
        instance.cancelButtonLoading = false
      })
      this.$notify({
        title: msg,
        message: msg,
        type: 'success'
      })
      this.$router.push({
        path: '../../standwebsite-return-manage',
        append: true,
        query: { ...this.userInfo }
      })
    },
    async handleSaveReturnInfo(data, instance) {
      const { msg } = await saveReturnInfos(data).finally(() => {
        instance.confirmButtonLoading = false
        instance.cancelButtonLoading = false
      })
      this.$notify({
        title: msg,
        message: msg,
        type: 'success'
      })
      this.$router.go(-1)
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 10px;
}

.refund-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 370px;
  margin: auto
}
</style>
